import * as React from 'react';
import animatedIntroMp4 from "../videos/TOSSAnimatedCompressed.mp4";
import animatedIntroWebm from "../videos/TOSSAnimatedCompressed.webm";

export function IntroVideo() {
    const videoRef = React.useRef(null);

    const [isPlaying, setIsPlaying] = React.useState(false);

    const togglePlay = () => {
        const video = videoRef.current;
        if (!video) return;

        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <div id='introVideo'>
            <div className='introVideoContainer'>
                <video
                    ref={videoRef}
                    width="70%"
                    controls={isPlaying}
                    style={{ background: "black" }}
                >
                    <source src={animatedIntroMp4} type='video/mp4' />
                    <source src={animatedIntroWebm} type='video/webm' />
                    Your browser does not support the video tag.
                </video>
                {!isPlaying && (
                <button className="playVideoButton" onClick={togglePlay}>{` ▶`}</button>
                )}
            </div>
        </div>
    );
}

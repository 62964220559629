import * as React from 'react';
import { useState, useEffect } from 'react';
import { HamburgerNav } from "./HamburgerNav";
import { Title } from './Title';
import landingVideoMp4 from '../videos/landingVideoCompressed.mp4';
import landingVideoWebm from '../videos/landingVideoCompressed.webm';

export function LandingScreen() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    // Check screen size on initial render and window resize
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div id="landingScreen">
      {!isSmallScreen && (
        <video className='landingVideo' autoPlay loop muted preload="auto">
          <source src={landingVideoMp4} type='video/mp4' />
          <source src={landingVideoWebm} type='video/webm' />
          Your browser does not support the video tag.
        </video>
      )}
      <Title />
      <HamburgerNav className="hamburgerNav" />
    </div>
  );
}

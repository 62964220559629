import { Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import Home from "./pages/Home";
import './App.css';

const About = lazy(() => import('./pages/About'));
const Questions = lazy(() => import('./pages/Questions'));
const Contact = lazy(() => import('./pages/Contact'));

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="About" element={<About />} />
        <Route path="/Questions" element={<Questions />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="/Home" element={<Home />} />
      </Routes>
    </Suspense>
  );
}

export default App;
